import axios, {AxiosResponse} from 'axios';
import {
    BUSINESS_INSURANCE_AGGREGATE_ENDPOINT,
    BUSINESS_INSURANCE_ENDPOINT, BUSINESS_INSURANCE_SETTINGS_ENDPOINT,
    DOCUMENTS_ACH_REQUEST_ENDPOINT, DOCUMENTS_ADMIN_IRS_LETTERS,
    DOCUMENTS_AUTH_FORMS_ENDPOINT, DOCUMENTS_EXPIRABLE_ENDPOINT,
    DOCUMENTS_FILLABLE_ENDPOINT, DOCUMENTS_FORM_8822_B_ENDPOINT, DOCUMENTS_IRS_LETTERS,
    DOCUMENTS_PUBLIC_FILLABLE_EXISTS_ENDPOINT, DOCUMENTS_PUBLIC_FORM_ENDPOINT,
    DOCUMENTS_PUBLIC_IT2104_ENDPOINT,
    DOCUMENTS_PUBLIC_W4_ENDPOINT,
    DOCUMENTS_PUBLIC_W8BEN_ENDPOINT,
    DOCUMENTS_PUBLIC_W9_ENDPOINT, DOCUMENTS_RECEIPTS_ENDPOINT, DOCUMENTS_REGISTER_FORM_8822_B_ENDPOINT,
    DOCUMENTS_REGISTER_W9_ENDPOINT,
    DOCUMENTS_REQUEST_IT2104_ENDPOINT, DOCUMENTS_RESERVE_AGREEMENT_ENDPOINT,
    DOCUMENTS_SIGNABLE_ENDPOINT, FILE_SIGNABLE_REFRESH_ENDPOINT
} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import CreateFillableDocumentRequestDTO from "@/dto/archive/CreateFillableDocumentRequestDTO";
import SaveW8BENRequestDTO from "@/dto/archive/SaveW8BENRequestDTO";
import FillableDocumentFilter from "@/dto/archive/FillableDocumentFilter";
import FillableDocumentDataDTO from "@/dto/archive/FillableDocumentDataDTO";
import QueryUtils from "@/utils/QueryUtils";
import CreateW9RequestDTO from "@/dto/archive/CreateW9RequestDTO";
import SaveW9RequestDTO from "@/dto/archive/SaveW9RequestDTO";
import Pageable from "@/dto/Pageable";
import SaveW4RequestDTO from "@/dto/archive/SaveW4RequestDTO";
import RequestIT2104RequestDTO from "@/dto/archive/RequestIT2104RequestDTO";
import SaveIT2104RequestDTO from "@/dto/archive/SaveIT2104RequestDTO";
import SignableDocumentDTO from "@/dto/files/SignableDocumentDTO";
import SignableDocumentFilter from "@/dto/archive/SignableDocumentFilter";
import Fill8822Request from "@/dto/Fill8822Request";
import {WorkspaceType} from "@/dto/auth/Workspace";
import {PropType} from "vue";
import ExpirableDocumentDTO from "@/dto/ExpirableDocumentDTO";
import Page from "@/dto/taxation/Page";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {CompanyDTO} from "@/dto/CompaniesDTO";
import ReceiptDTO, {ReceiptStatus} from "@/dto/ReceiptDTO";
import {
    AdminIRSLetterFilter,
    CreateIRSLetterPayload,
    IRSLetter, IRSLetterFilter,
    UpdateIRSLetterPayload
} from "@/components/documents/notice/IRSLetter";
import CreateReserveDocument from "@/components/profile/company/CreateReserveDocument";
import InsuranceFilter from "@/dto/archive/insurance/InsuranceFilter";
import InsuranceDTO from "@/dto/archive/insurance/InsuranceDTO";
import CreateInsurancePayload from "@/dto/archive/insurance/CreateInsurancePayload";
import InsuranceSettingsDTO from "@/dto/archive/insurance/InsuranceSettingsDTO";
import InsuranceAggregateDTO from "@/dto/archive/insurance/InsuranceAggregateDTO";

class DocumentService {

    register(request: CreateFillableDocumentRequestDTO) {
        return axios.post(DOCUMENTS_FILLABLE_ENDPOINT, request, {headers: authHeader()});
    }


    saveW8ben(request: SaveW8BENRequestDTO) {
        return axios.put(DOCUMENTS_PUBLIC_W8BEN_ENDPOINT, request);
    }

    getFillableByFilter(filter: FillableDocumentFilter) {
        const queryString = QueryUtils.buildQueryString(
            ["requestingCompanyId", filter.requestingCompanyId],
            ["documentType", filter.documentType],
        );
        return axios.get<Pageable<FillableDocumentDataDTO>>(`${DOCUMENTS_FILLABLE_ENDPOINT}${queryString}`, {headers: authHeader()});
    }

    existsByPublicId(publicId: string) {
        return axios.get<boolean>(`${DOCUMENTS_PUBLIC_FILLABLE_EXISTS_ENDPOINT}/${publicId}`);
    }

    registerW9(request: CreateW9RequestDTO) {
        return axios.post<FillableDocumentDataDTO>(`${DOCUMENTS_REGISTER_W9_ENDPOINT}`, request, {headers: authHeader()});
    }

    saveW9(request: SaveW9RequestDTO) {
        return axios.put<void>(DOCUMENTS_PUBLIC_W9_ENDPOINT, request);
    }

    saveW4(request: SaveW4RequestDTO) {
        return axios.put<void>(DOCUMENTS_PUBLIC_W4_ENDPOINT, request);
    }

    requestIt2104(request: RequestIT2104RequestDTO) {
        return axios.post<FillableDocumentDataDTO>(DOCUMENTS_REQUEST_IT2104_ENDPOINT, request, {headers: authHeader()});
    }

    fillIt2104(request: SaveIT2104RequestDTO) {
        return axios.post<void>(DOCUMENTS_PUBLIC_IT2104_ENDPOINT, request, {headers: authHeader()});
    }

    loadSignableDocuments(filter: SignableDocumentFilter) {
        const queryString = QueryUtils.buildQueryString(
            ["customerType", filter.customerType],
            ["customerId", filter.customerId],
            ["documentType", filter.documentType],
            ["pageNumber", filter.page],
            ["documentSubtype", filter.documentSubType],
            ["customer", filter.customer]
        );
        return axios.get<Pageable<SignableDocumentDTO>>(`${DOCUMENTS_SIGNABLE_ENDPOINT}${queryString}`, {headers: authHeader()});
    }

    sendAuthForm(document: SignableDocumentDTO) {
        return axios.post<void>(DOCUMENTS_AUTH_FORMS_ENDPOINT, document, {headers: authHeader()});
    }

    deleteSignableDocument(id: number) {
        return axios.delete<void>(`${DOCUMENTS_SIGNABLE_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    fillF8822B(request: Fill8822Request) {
        return axios.put(DOCUMENTS_FORM_8822_B_ENDPOINT, request, {headers: authHeader()})
    }

    registerF8822B(companyId: number) {
        return axios.post<FillableDocumentDataDTO>(DOCUMENTS_REGISTER_FORM_8822_B_ENDPOINT, {companyId: companyId}, {headers: authHeader()})
    }

    refreshSignableDocument(document: SignableDocumentDTO) {
        return axios.post<SignableDocumentDTO>(FILE_SIGNABLE_REFRESH_ENDPOINT, document, {headers: authHeader()});
    }

    requestAch(id: number | null, type: WorkspaceType): Promise<AxiosResponse<void>> {
        return axios.post<void>(`${DOCUMENTS_ACH_REQUEST_ENDPOINT}`, {
            bankAccountId: id,
            customerType: type
        }, {headers: authHeader()})
    }

    loadPreparedPublicForm<T>(publicId: string) {
        return axios.get<T>(`${DOCUMENTS_PUBLIC_FORM_ENDPOINT}/${publicId}`)
    }

    getExpirableDocumentPage(pageNumber: number, pageSize: number) {
        const queryString = QueryUtils.buildQueryString(
            ["pageNumber", pageNumber],
            ["pageSize", pageSize]
        )
        return axios.get<Page<ExpirableDocumentDTO>>(`${DOCUMENTS_EXPIRABLE_ENDPOINT}${queryString}`, {headers: authHeader()})
    }

    saveReceipt(companyId: number, fileIds: number[]) {
        return axios.post<FileMetaDTO>(DOCUMENTS_RECEIPTS_ENDPOINT, {companyId: companyId, fileIds: fileIds}, {headers: authHeader()})
    }

    getReceiptsPage(pageNumber: number, pageSize: number, status: ReceiptStatus | null, companyId?: number | null) {
        const queryString = QueryUtils.buildQueryString(
            ["pageNumber", pageNumber],
            ["pageSize", pageSize],
            ["status", status],
            ["companyId", companyId]
        )
        return axios.get<Page<ReceiptDTO>>(`${DOCUMENTS_RECEIPTS_ENDPOINT}${queryString}`, {headers:authHeader()});
    }

    getReceipt(receiptId: number){
        return axios.get<ReceiptDTO>(`${DOCUMENTS_RECEIPTS_ENDPOINT}/${receiptId}`, {headers:authHeader()});
    }

    changeReceiptStatus(receiptId: number, status: ReceiptStatus){
        return axios.put(`${DOCUMENTS_RECEIPTS_ENDPOINT}/${receiptId}/status`, {value: status}, {headers:authHeader()});
    }

    addReceiptTags(receiptId: number, tag: string){
        return axios.post(`${DOCUMENTS_RECEIPTS_ENDPOINT}/${receiptId}/tags`, {value: tag}, {headers:authHeader()});
    }
    getAllReceiptsTags(){
        return axios.get<Array<string>>(`${DOCUMENTS_RECEIPTS_ENDPOINT}/tags`, {headers:authHeader()});
    }

    changeReceiptAmount(receiptId: number, amount: number){
        return axios.put(`${DOCUMENTS_RECEIPTS_ENDPOINT}/${receiptId}/amount`, {value: amount}, {headers:authHeader()});
    }

    getIRSLettersByFilterForAdmin(filter: AdminIRSLetterFilter) {
        const queryString = QueryUtils.objectToQueryString(filter);
        return axios.get<Pageable<IRSLetter>>(`${DOCUMENTS_ADMIN_IRS_LETTERS}${queryString}`, {headers: authHeader()})
    }

    getIRSLettersByFilter(filter: IRSLetterFilter) {
        const queryString = QueryUtils.objectToQueryString(filter);
        return axios.get<Pageable<IRSLetter>>(`${DOCUMENTS_IRS_LETTERS}${queryString}`, {headers: authHeader()})
    }

    deleteIRSLetter(id: number) {
        return axios.delete<void>(`${DOCUMENTS_ADMIN_IRS_LETTERS}/${id}`, {headers: authHeader()})
    }

    createIRSLetter(req: CreateIRSLetterPayload): Promise<AxiosResponse<void>> {
        return axios.post<void>(`${DOCUMENTS_ADMIN_IRS_LETTERS}`, req, {headers: authHeader()})
    }

    updateIRSLetter(req: UpdateIRSLetterPayload) {
        return axios.put(`${DOCUMENTS_ADMIN_IRS_LETTERS}`, req, {headers: authHeader()})
    }

    requestReserveAgreement(req: CreateReserveDocument) {
        return axios.post<void>(`${DOCUMENTS_RESERVE_AGREEMENT_ENDPOINT}`, req,{headers: authHeader()})
    }

    getInsurances(filter: InsuranceFilter) {
        const queryString = QueryUtils.objectToQueryString(filter);
        return axios.get<Array<InsuranceDTO>>(`${BUSINESS_INSURANCE_ENDPOINT}${queryString}`, {headers: authHeader()});
    }

    addInsurance(insurance: CreateInsurancePayload) {
        return axios.post<void>(`${BUSINESS_INSURANCE_ENDPOINT}`, insurance,{headers: authHeader()})
    }
    getInsuranceSettings(companyId: number) {
        return axios.get<InsuranceSettingsDTO>(`${BUSINESS_INSURANCE_SETTINGS_ENDPOINT}/${companyId}`, {headers: authHeader()});
    }

    saveInsuranceSettings(companyId: number, insuranceSettings: InsuranceSettingsDTO) {
        return axios.put<void>(`${BUSINESS_INSURANCE_SETTINGS_ENDPOINT}/${companyId}`, insuranceSettings,{headers: authHeader()});
    }

    getInsuranceAggregate(pageNumber: number, pageSize: number) {
        const queryString = QueryUtils.buildQueryString(
          ["pageNumber", pageNumber],
          ["pageSize", pageSize]
        )
        return axios.get<Pageable<InsuranceAggregateDTO>>(`${BUSINESS_INSURANCE_AGGREGATE_ENDPOINT}${queryString}`, {headers: authHeader()});
    }
}

export default new DocumentService();
