













































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import EmploymentService from "@/services/EmploymentService";
import {EmploymentFilter} from "@/dto/payroll/Filters";
import Workspaces from "@/state/Workspaces";
import PayrollService from "@/services/PayrollService";
import {
	CreateTimeSheetRecordPayload,
	TimeSheetFilter,
	TimeSheetPayDate,
	TimeSheetPayPeriod,
	TimeSheetRecord,
	UpdateTimeSheetRecordPayload,
} from "@/dto/payroll/timesheet/TimesheetPayloads";
import {processError} from "@/utils/ComponentUtils";
import Application from "@/state/Application";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import PortalTime from "@/components/common/PortalTime.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import {prettyEnum} from "../../../utils/StringUtils";
import {PayType} from "@/constants/TarifficationConstants";
import TimeSheetRecordEditorModal from "@/components/payroll/timesheet/TimeSheetRecordEditorModal.vue";
import Notifications from "@/state/Notifications";
import RouteNames from "@/router/RouteNames";
import {IEmployment} from "@/dto/payroll/IEmployment";
import AdminService from "@/services/AdminService";
import {namespace} from "vuex-class";
import ConfirmComponent from "@/components/payroll/business/ConfirmComponent.vue";
import {CounterpartyType} from "@/constants/CounterpartyType";
import moment from "moment-timezone";
import { Moment } from "moment-timezone";
import { DateTime } from "luxon";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";

const AppModule = namespace("App");

@Component({
	computed: {
		PayType() {
			return PayType
		}
	},
	methods: {
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		},
		RouteNames() {
			return RouteNames
		},
		prettyEnum
	},
	components: {PortalSelect, PaginationComponent, PortalTime}
})
export default class IndividualTimesheet extends Vue{

	private week = moment().format("YYYY-MM-DD")
	private timeLine: Array<Moment> = [];

	private timeFormat = "hh:mm A"

	private timerTrigger = 0

	@AppModule.State
	private isMobile!: boolean;

	private employmentFilter: EmploymentFilter = new EmploymentFilter(
		{
			types: ["STAFF", "FOREIGN"],
			contractorType: CounterpartyType.PERSON,
			detailsId: Workspaces.getCurrent.id,
			status: [EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS, EmploymentStatus.NEW]
		}
	)

	private employmentIdArr: Array<number> = [];

	private updateCount = 0;

	private timesheetPeriods: Array<TimeSheetPayPeriod> = [];

	private async loadEmploymentIds() {
		if (Workspaces.isCurrentWorkspaceSublimeEmployee) {
			return AdminService.getEmployeePersonProfile().then(
				res => this.employmentFilter.detailsId = res.data.id,
				err => processError(err, this)
			).then(
				() => EmploymentService.getAllByFilter(this.employmentFilter).then(
					res => {
						this.employmentIdArr = res.data.data.map(it => it.id as number)
					},
					err => processError(err, this)
				)
			)
		} else {
			return EmploymentService.getAllByFilter(this.employmentFilter).then(
				res => {
					this.employmentIdArr = res.data.data.map(it => it.id as number)
				},
				err => processError(err, this)
			)
		}
	}

	@Watch("week")
	@Watch("updateCount")
	private reload() {
		this.initCurrentTimeLine(moment(this.week));
		Application.startLoading();
		this.loadEmploymentIds().then(
			() => {
				if (this.employmentIdArr.length > 0) this.loadTimesheetRecords()
			},
			err => processError(err, this)
		).then(() => Application.stopLoading())
	}

	public created(): void {
		this.initCurrentTimeLine()
		this.reload();
		setInterval(() => this.timerTrigger += 1, 1000)
	}

	private async loadTimesheetRecords() {
		const timesheetFilter = new TimeSheetFilter(
			{
				startDate: this.timeLine[0].format("YYYY-MM-DD"),
				endDate: this.timeLine[this.timeLine.length - 1].format("YYYY-MM-DD"),
				employmentsId: this.employmentIdArr
			}
		)
		PayrollService.getTimeSheetPayPeriod(timesheetFilter).then(
			res => {
				this.timesheetPeriods = res.data
				this.timesheetPeriods.forEach(it => it.payDates = this.prepareTimeSheetRecords(it.payDates, it.employment))
			},
			err => processError(err, this)
		)
	}

	private prepareTimeSheetRecords(payDates: Array<TimeSheetPayDate>, employment: IEmployment): Array<TimeSheetPayDate> {
		const res: Array<TimeSheetPayDate> = [];
		for (let i = 0; i < this.timeLine.length; i++) {
			let payDate = payDates[i];
			payDate.records.forEach(it => {
				it.workPeriodStart = new Date(it.workPeriodStart)
				if (it.workPeriodEnd) {
					it.workPeriodEnd = new Date(it.workPeriodEnd)
				}
			})
			const defaultStart = this.timeLine[i].toDate();
			const time = new Date()
			defaultStart.setHours(time.getHours(), time.getMinutes(), 0, 0)
			payDate.records.push(new TimeSheetRecord(defaultStart, "REGULAR", defaultStart, employment))
			res.push(payDate)
			payDate.timePoint = this.timeLine[i]
		}

		return res;
	}

	private initCurrentTimeLine(m: Moment = moment()): void {
		const currentWeekIndexDay = moment(m).weekday()
		this.timeLine = [];
		for (let i = currentWeekIndexDay; i > 0; i--) {
			this.timeLine.push(moment(m).add({day: i * -1}))
		}
		this.timeLine.push(moment(m))
		for (let i = 1; i < 7 - currentWeekIndexDay; i++) {
			this.timeLine.push(moment(m).add({day: i}))
		}
	}

	public getDayOfWeekByIndexDay(indexDay: number): string {
		return moment().localeData().weekdaysShort()[indexDay]
	}

	public getMonthByIndexMonth(indexMonth: number): string {
		return moment().localeData().months()[indexMonth]
	}

	private expandRecord(id: string) {
		if (document.getElementById(`${id}`)?.classList.contains("timesheet__date_active")) {
			document.getElementById(`${id}`)!.classList.remove("timesheet__date_active")
		} else {
			document.getElementById(`${id}`)!.classList.add("timesheet__date_active")
		}
	}

	private start(employment: IEmployment) {
		const defaultStart = moment().toDate();
		const time = new Date()
		defaultStart.setHours(time.getHours(), time.getMinutes(), 0, 0)
		this.create(new TimeSheetRecord(defaultStart, "REGULAR", defaultStart, employment))
	}

	private stop(record: TimeSheetRecord) {
		record.workPeriodStart = new Date(record.workPeriodStart )
		record.workPeriodEnd = new Date()
		this.update(record)
	}

	private editRecord(record: TimeSheetRecord) {
		this.$root.$emit('bv::hide::popover')
		this.$modal.show(
			TimeSheetRecordEditorModal,
			{
				record: record,
				onUpdate: this.update
			}
		)
	}

	private create(record: TimeSheetRecord) {
		Application.startLoading()
		this.prepareRecordTime(record)
		PayrollService.createTimesheetRecord(new CreateTimeSheetRecordPayload(record)).then(
			() => {
				Notifications.success("Changes was saved")
				this.updateCount += 1;
			},
			err => processError(err, this)
		)
	}

	private prepareRecordTime(record: TimeSheetRecord) {
		record.workPeriodEnd?.setSeconds(0, 0)
		record.workPeriodStart.setSeconds(0, 0)
		if (record.workPeriodEnd && record.workPeriodStart.getDate() == record.workPeriodEnd.getDate() && record.workPeriodStart.getTime() > record.workPeriodEnd.getTime()) {
			record.workPeriodEnd = moment(record.workPeriodEnd).add({day: 1}).toDate()
		}
		record.originalStartTime?.setSeconds(0, 0)
		record.originalEndTime?.setSeconds(0, 0)
	}

	private update(record: TimeSheetRecord) {
		Application.startLoading()
		this.prepareRecordTime(record)
		PayrollService.updateTimesheetRecord(new UpdateTimeSheetRecordPayload(record)).then(
			() => {
				Notifications.success("Changes was saved")
				this.updateCount += 1;
			},
			err => {
				processError(err, this);
				this.updateCount += 1
			}
		)
	}


	private isOpenRecordExistInDay(day: TimeSheetPayDate) {
		return !!day.records.find(it => it.workPeriodEnd == null && it.id != null)
	}


	get resolvePreviousWeek(): string {
		const previousFirstDay = moment(this.week).add({week: -1}).set({weekday: 0});
		const previousLastDay = moment(this.week).add({week: -1}).set({weekday: 6});
		return `${previousFirstDay.format("MM.DD")} - ${previousLastDay.format("MM.DD")}`
	}

	get resolveCurrentWeek(): string {
		const currentFirstDay = moment(this.week).set({weekday: 0});
		const currentLastDay = moment(this.week).set({weekday: 6});
		return `${currentFirstDay.format("MM.DD")} - ${currentLastDay.format("MM.DD")}`
	}

	get resolveNextWeek(): string {
		const nextFirstDay = moment(this.week).add({week: 1}).set({weekday: 0});
		const nextLastDay = moment(this.week).add({week: 1}).set({weekday: 6});
		return `${nextFirstDay.format("MM.DD")} - ${nextLastDay.format("MM.DD")}`
	}

	private remove(record: TimeSheetRecord) {
		this.$modal.show(
			DeleteConfirmation,
			{
				targetName: "timesheet record",
				onDelete: () => this.removeRecord(record)
			}
		)
	}

	private removeRecord(record: TimeSheetRecord) {
		Application.startLoading();
		PayrollService.removeRecord(record).then(
			() => {
				Notifications.success("The record was successfully deleted")
				Application.stopLoading()
				this.updateCount += 1
			},
			err => processError(err, this)
		)
	}

	timer(period: TimeSheetPayPeriod): string {
		let payDate = period?.payDates?.find(it => it.timePoint.dayOfYear() == moment().dayOfYear())
		if (payDate) {
			let currentWorkDuration = payDate.durationInSeconds;
			if (payDate.records.length >= 2 && !payDate.records[payDate.records.length - 2].workPeriodEnd) {
				currentWorkDuration += Math.floor((new Date().getTime() - payDate.records[payDate.records.length - 2].workPeriodStart.getTime()) / 1000)
			}
			return `${Math.floor(currentWorkDuration / 3600)}h ${Math.floor(currentWorkDuration / 60 % 60)}m ${currentWorkDuration % 60}s`
		} else {
			return "0h 0m 0s"
		}
	}

	timeFormats(): SelectOption[] {
		return [
			SelectOption.builder().title("12 Hour").value("hh:mm A").build(),
			SelectOption.builder().title("24 Hour").value("HH:mm").build()
		]
	}
}

