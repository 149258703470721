import RouteNames from "@/router/RouteNames";
import {Role} from "@/constants/Role";
import {getTarget} from "http-proxy-middleware/dist/router";
import { InsuranceType } from "@/dto/archive/insurance/InsuranceType";

export class RouterTreeNode {

    public name: string | null = null;

    public children: Array<RouterTreeNode> = [];

    public parentName: string | null = null; // JS throws circular structure error on set node

    public isAdmin = false;

    public availableForAdminOnSidePanelByDefault = true; // when navigation accessible only from admin panel

    public isLoggedIn = true; // available only when logged in

    public isLoggedOut = false; // available only when logged out

    public isHidden = false; // Isn't visible. Need to set parent for dynamic component outside common hierarchy

    public availableForCompany = false;

    public availableForPerson = false;

    public availableForEmployee = false;

    public availableForEveryoneOnSidePanelByDefault = true;

    public role: Role | null = null;

    public insuranceType: InsuranceType | null = null;

    constructor(init?: Partial<RouterTreeNode>) {
        Object.assign(this, init);
        this.children.forEach(c => c.parentName = this.name);
    }

    public hasChildren(childName: string): boolean {
        return this.children.length != 0 && (this.name == childName || !!this.children.find(c => c.hasChildren(childName)));
    }
}

export const nodeMap = new Map<string | null, RouterTreeNode>();

function node(init?: Partial<RouterTreeNode>): RouterTreeNode {
    const node = new RouterTreeNode(init);
    nodeMap.set(node.name, node);
    return node;
}

function pseudoNode(name: string | null) {
    return node({name: name, isHidden: true});
}

export const RouterTree = node({
    name: null, children: [
        node({name: RouteNames.HOME, isLoggedIn: false}),
        node({name: RouteNames.LOGIN, isAdmin: false, isLoggedIn: false, isLoggedOut: true}),
        node({
            name: RouteNames.USER_PROFILE, children: [
                node({name: RouteNames.PROFILE_ACCOUNT}),
                node({name: RouteNames.PROFILE_ADDRESS, availableForPerson: true}),
                node({name: RouteNames.PROFILE_HEADQUARTER, availableForCompany: true}),
                node({name: RouteNames.PROFILE_BRANCH_LIST, availableForCompany: true}),
                pseudoNode(RouteNames.PROFILE_BRANCH),
                node({name: RouteNames.PROFILE_MARITAL_STATUS, isAdmin: true, availableForPerson: true}),
                node({name: RouteNames.PROFILE_SHAREHOLDER, availableForCompany: true}),
                node({name: RouteNames.PROFILE_PERSON_DETAILS, availableForPerson: true}),
                node({name: RouteNames.PROFILE_COMPANY_DETAILS, availableForCompany: true}),
                node({name: RouteNames.PROFILE_COMPANY_BANK_STATEMENTS, availableForCompany: true}),
                node({name: RouteNames.PROFILE_COMPANY_BUSINESS_TAX_RETURN, availableForCompany: true}),
                node({name: RouteNames.PROFILE_BANK_ACCOUNTS, availableForPerson: true, availableForCompany: true}),
                node({name: RouteNames.PROFILE_OTHER_BANK_PRODUCTS, availableForCompany: true}),
                pseudoNode(RouteNames.PROFILE_BANK_ACCOUNT),
                node({name: RouteNames.PROFILE_PERSON_DOCUMENTS, availableForPerson: true}),
            ]
        }),
        node({
            name: RouteNames.REQUESTS, isAdmin: true
        }),
        pseudoNode(RouteNames.RCCR),
        pseudoNode(RouteNames.W9CR),
        pseudoNode(RouteNames.FRSO),
        pseudoNode(RouteNames.RER),
        pseudoNode(RouteNames.RIDC),
        node({
            name: RouteNames.ADMIN, isAdmin: true, children: [
                node({name: RouteNames.ADMIN_INDIVIDUALS_LIST, isAdmin: true}),
                node({name: RouteNames.ADMIN_COMPANIES_LIST, isAdmin: true}),
            ]
        }),
        node({
            name: RouteNames.PAYROLL_INDIVIDUAL, availableForPerson: true, role: Role.PAYROLL_MANAGER, children: [
                node({name: RouteNames.PAYROLL_INDIVIDUAL_PAYSTUBS}),
                node({
                    name: RouteNames.PAYROLL_INDIVIDUAL_FORMS, children: [
                        node({name: RouteNames.PAYROLL_INDIVIDUAL_FORMS_W2}),
                        node({name: RouteNames.PAYROLL_INDIVIDUAL_FORMS_1099}),
                    ]
                })
            ]
        }),
        node({ name: RouteNames.TIMESHEET, role: Role.PAYROLL_MANAGER, availableForPerson: true, availableForCompany: true, availableForEmployee: true}),
        node({
                name: RouteNames.HR, availableForCompany: true, children:[
                    node({name: RouteNames.HR_EMPLOYEES}),
                    node({name: RouteNames.HR_CONTRACTORS}),
                    node({name: RouteNames.HR_FOREIGN_CONTRACTORS}),
                    node({name: RouteNames.HR_REQUESTS}),
                ]
            }
        ),
        node({
            name: RouteNames.PAYROLL_BUSINESS, availableForCompany: true, role: Role.PAYROLL_MANAGER, children: [
                node({name: RouteNames.PAYROLL_CLOSE_PAY_PERIOD}),
                node({name: RouteNames.PAYROLL_BUSINESS_PAYSTUBS}),
                node({name: RouteNames.PAYROLL_BUSINESS_DETAILS}),
                node({name: RouteNames.PAYROLL_BUSINESS_GENERAL_DETAILS}),
                node({
                    name: RouteNames.PAYROLL_BUSINESS_REPORTS, children: [
                        node({name: RouteNames.PAYROLL_BUSINESS_REPORTS_FEDERAL}),
                        node({name: RouteNames.PAYROLL_BUSINESS_REPORTS_REGIONAL}),
                    ],
                    availableForCompany: true
                }),
                node({
                    name: RouteNames.PAYROLL_BUSINESS_FORMS, children: [
                        node({name: RouteNames.PAYROLL_BUSINESS_FORMS_W2}),
                        node({name: RouteNames.PAYROLL_BUSINESS_FORMS_1099}),
                        node({name: RouteNames.PAYROLL_BUSINESS_FORMS_1042S}),
                        node({name: RouteNames.PAYROLL_BUSINESS_FORMS_K1}),
                    ]
                }),
                node({name: RouteNames.PAYROLL_COMPANY_PROJECT}),
                pseudoNode(RouteNames.PAYROLL_BUSINESS_EMPLOYEES),
                pseudoNode(RouteNames.PAYROLL_COMPANY_EMPLOYEE_DETAILS),
                pseudoNode(RouteNames.PAYROLL_COMPANY_BUSINESS_CONTRACTOR),
                pseudoNode(RouteNames.PAYROLL_COMPANY_INDIVIDUAL_CONTRACTOR),
                pseudoNode(RouteNames.PAYROLL_COMPANY_BUSINESS_FOREIGN_CONTRACTOR),
                pseudoNode(RouteNames.PAYROLL_COMPANY_INDIVIDUAL_FOREIGN_CONTRACTOR),

            ]
        }),
        node({
            name: RouteNames.TAXATION_INDIVIDUAL, availableForPerson: true, children: [
                node({name: RouteNames.TAXATION_INDIVIDUAL_ESTIMATED_SUBSCRIPTIONS, isAdmin: true}),
                node({name: RouteNames.PAYROLL_INDIVIDUAL_INCOME_TAX_FEDERAL, availableForPerson: true}),
                node({name: RouteNames.PAYROLL_INDIVIDUAL_INCOME_TAX_STATE, availableForPerson: true}),
                pseudoNode(RouteNames.TAXATION_INDIVIDUAL_ESTIMATED_SUBSCRIPTION),
                pseudoNode(RouteNames.TAXATION_INDIVIDUAL_ESTIMATED_OFFER,)
            ]
        }),
        node({
            name: RouteNames.ARCHIVE,
            children: [
                node({
                    name: RouteNames.ARCHIVE_SIGNABLE_LIST,
                    isAdmin: true
                }),
                node({
                    name: RouteNames.ARCHIVE_AUTH,
                    isAdmin: true,
                    availableForCompany: true,
                    availableForPerson: true
                }),
                node({name: RouteNames.ARCHIVE_W8BEN, isAdmin: true, availableForCompany: true}),
                node({name: RouteNames.ARCHIVE_W9, isAdmin: true, availableForCompany: true}),
                node({name: RouteNames.ARCHIVE_W4, isAdmin: true, availableForCompany: true}),
                node({name: RouteNames.ARCHIVE_IT2104, isAdmin: true, availableForCompany: true}),
                node({name: RouteNames.ARCHIVE_F8822, isAdmin: true, availableForCompany: true}),
                node({
                    name: RouteNames.ARCHIVE_EXPIRING,
                    isAdmin: true,
                    availableForEmployee: true,
                    availableForPerson: true,
                    availableForCompany: true
                }),
                node({
                    name: RouteNames.ARCHIVE_RECEIPTS_CLIENT,
                    availableForAdminOnSidePanelByDefault: false,
                    availableForCompany: true
                }),
                node({name: RouteNames.ARCHIVE_RECEIPTS_ADMIN, isAdmin: true}),
                node({name: RouteNames.ARCHIVE_ADMIN_IRS_LETTERS, isHidden: true}),
                node({name: RouteNames.ARCHIVE_IRS_LETTERS, isHidden: true}),
                node({
                    name: RouteNames.ARCHIVE_INSURANCE,
                    isAdmin: true,
                    children: [
                        node({name: RouteNames.ARCHIVE_INSURANCE_COMPANIES, isAdmin: true}),
                        node({name: RouteNames.ARCHIVE_INSURANCE_SETTINGS, isAdmin: true, availableForCompany: true}),
                        node({name: RouteNames.ARCHIVE_INSURANCE_GENERAL_LIABILITY, insuranceType: InsuranceType.GENERAL_LIABILITY, isAdmin: true, availableForCompany: true}),
                        node({name: RouteNames.ARCHIVE_INSURANCE_PROFESSIONAL_LIABILITY, insuranceType: InsuranceType.PROFESSIONAL_LIABILITY, isAdmin: true, availableForCompany: true}),
                        node({name: RouteNames.ARCHIVE_INSURANCE_WORKERS_COMPENSATION, insuranceType: InsuranceType.WORKERS_COMPENSATION, isAdmin: true, availableForCompany: true}),
                        node({name: RouteNames.ARCHIVE_INSURANCE_DISABILITY, insuranceType: InsuranceType.DISABILITY, isAdmin: true, availableForCompany: true}),
                    ]
                }),
                node({ name: RouteNames.ARCHIVE_PAYMENTS, isAdmin: true }),
                pseudoNode(RouteNames.ARCHIVE_PAYMENT),
            ]
        }),
        node({
            name: RouteNames.SALES_TAX, availableForCompany: true, children: [
                node({name: RouteNames.SALES_TAX_DOCUMENTS}),
                node({name: RouteNames.SALES_TAX_REPORTS}),
                node({name: RouteNames.SALES_TAX_PERMITS}),
                node({name: RouteNames.SALES_TAX_SUBSCRIPTIONS}),
                node({name: RouteNames.SALES_TAX_WORKFLOWS_ADMIN}),
                node({name: RouteNames.SALES_TAX_WORKFLOWS}),
                node({name: RouteNames.SALES_TAX_SCHEDULE}),
                pseudoNode(RouteNames.SALES_TAX_WORKFLOW)
            ]
        }),
        node({
            name: RouteNames.SUPPORT,
            isAdmin: true,
            availableForCompany: true,
            availableForPerson: true,
            availableForEmployee: true
        }),
        node({
            name: RouteNames.ADMIN_PANEL, isAdmin: true, children: [
                node({name: RouteNames.AUDIT, isAdmin: true, role: Role.ADMIN}),
                node({name: RouteNames.MAIL_REGISTRY, isAdmin: true, role: Role.ADMIN}),
                node({
                    name: RouteNames.SUBLIME, isAdmin: true, children: [
                        node({name: RouteNames.OFFICES, role: Role.ADMIN}),
                        pseudoNode(RouteNames.OFFICE),
                        node({name: RouteNames.EMPLOYEES, role: Role.ADMIN}),
                        pseudoNode(RouteNames.EMPLOYEE),
                        node({name: RouteNames.SUBLIME_AUTO_ASSIGNMENTS, role: Role.ADMIN}),
                        node({name: RouteNames.REPORTS}),
                    ]
                }),
            ]
        }),
        node({name: RouteNames.LOCATIONS, isLoggedIn: false}),
    ]
})
